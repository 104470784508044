import React, {useState, useEffect} from 'react'
import {Container, Row, Col, Form, Button} from 'react-bootstrap'
import {Link, useNavigate} from 'react-router-dom';
import AuthService from '../services/AuthService';
import {v4 as uuidv4} from 'uuid';
import axios from "axios";
import {API_ENDPOINT, AUTH_LOGIN, SUCCESS, BANNER_VIEW_FOR_USER_IN} from "../constant/";
import {encryptData, decryptData} from "../utils/crypto";
import {getDeviceInfo} from "../utils/imData";
import sitelogo from '../assets/images/sitelogo.png';
import {toastSuccess, toastError} from "../utils/toastMsg";
import CryptoJS from "crypto-js";

const Login = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        userName: '',
        password: ''
    });
    const [loaderData, setLoaderData] = useState(true);
    const [logoData, setLogoData] = useState(sitelogo);
    const fullUrl = window.location.origin;
    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (
                !formData.userName ||
                !formData.password
            ) {
                toastError("Please fill in all fields")
                return;
            }
            if (formData.password.length < 8) {
                toastError("Password must be greater than 8 characters.")
                return;
            }
            let domain = window.location.hostname;
            const {deviceType, browser, userAgent, newDeviceId, ip} = await getDeviceInfo();
            let data = encryptData({
                "userName": formData.userName,
                "password": formData.password,
                "loginBy": "Web",
                "browser": browser,
                "userAgent": userAgent,
                "deviceId": newDeviceId,
                "deviceType": deviceType,
                "domain": domain,
                "ipAddress": ip,
                "parentUsername": process.env.REACT_APP_USERNAME,
                "checkUsername": process.env.REACT_APP_CHECK_USERNAME,
            });
            data = JSON.stringify({data: data});
            axios
                .post(API_ENDPOINT + AUTH_LOGIN, data, {
                    headers: {
                        // "Content-Type": "multipart/form-data", // Set the content type
                        'Content-Type': 'application/json',
                        "deviceType": deviceType
                    },
                })
                .then((response) => {
                    if (response.data.statusCode == SUCCESS) {
                        const rdata = decryptData(response.data.data)
                        localStorage.setItem(
                            "authenticated",
                            JSON.stringify(rdata)
                        );
                        localStorage.setItem("token", response.data.meta.token);
                        localStorage.setItem("login", true);
                        localStorage.setItem("isNotificationRead", 0);
                        localStorage.setItem("isTermsAndConditions", 0);
                        // window.location.href = `${process.env.PUBLIC_URL}/dashboard`;
                        window.location.href = `${fullUrl}/dashboard`;
                        toastSuccess('Login Successfully')
                    } else {
                        toastError(response.data.message)
                    }
                })
                .catch((error) => {
                    toastError(error.response.data.message)
                    console.error("Login error:", error); // Handle error
                });
        } catch (error) {
            console.log(error)
            toastError('Login failed ' + error)
        }
    };
    const getMainDomain = () => {
        const host = window.location.hostname;
        const parts = host.split(".");

        if (parts.length > 2) {
            return parts.slice(-2).join("."); // Get last two parts for main domain
        }
        return host; // Already main domain
    };
    const getLogoData = async () => {
        try {
            const mainDomain = getMainDomain();
            var data = {'mainDomain': mainDomain};
            var encryptedData = encryptData(data);
            data = JSON.stringify({data: encryptedData});
            const userAgent = navigator.userAgent;
            let newDeviceType = "desktop";
            if (/Mobi|Android/i.test(userAgent)) {
                newDeviceType = "mobile";
            }
            axios
                .post(API_ENDPOINT + BANNER_VIEW_FOR_USER_IN, data, {
                    headers: {
                        // "Content-Type": "multipart/form-data", // Set the content type
                        'Content-Type': 'application/json',
                        "deviceType": newDeviceType
                    },
                })
                .then((response) => {
                    if (response.data.statusCode == SUCCESS) {
                        const rdata = decryptData(response.data.data)
                        if (rdata?.userD?.logoImageDataUrl) {
                            setLogoData(rdata.userD.logoImageDataUrl)
                        }
                        setLoaderData(false);
                    } else {
                        toastError(response.data.message)
                    }
                })
                .catch((error) => {
                    // toastError(error.response.data.message)
                    console.error("error:", error); // Handle error
                });
        } catch (error) {
            console.log(error)
            // toastError('failed ' + error)
        }
    };
    useEffect(() => {
        if (process.env.REACT_APP_CHECK_USERNAME == 1) {
            getLogoData()
        } else {
            setLoaderData(false);
        }
    }, []);
    return (
        <>
            {loaderData ?
                <div class="page-loader">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                : ""}
            <div className="login-wrapper d-flex v-center j-center">
                <div className='login-logo-block d-flex ptb-20'>
                    {logoData ?
                        <img src={logoData} alt=''/>
                        : ''}
                </div>
                <div className='box-screen'>
                    <div className="login-block">
                        <div className='form-wrapper ml-auto mr-auto'>

                            <h2 className='tl'>Log in to your Account</h2>

                            <Form className='form-inner d-flex' onSubmit={handleSubmit}>
                                <Form.Group className='input-field full-width'>
                                    <label className='label'>User Name</label>
                                    <Form.Control type='text' placeholder='Enter Username' value={formData.userName}
                                                  name="userName" id="userName" onChange={handleInputChange}/>
                                </Form.Group>

                                <Form.Group className='input-field full-width'>
                                    <label className='label'>Password</label>
                                    <Form.Control type='password' placeholder='Enter your password'
                                                  value={formData.password} name="password" id="password"
                                                  onChange={handleInputChange}/>
                                </Form.Group>

                                <Form.Group className='input-field full-width'>
                                    <div className='remember-block d-flex v-center'>
                                        <label className='checkbox'><Form.Control type='checkbox'/><span
                                            className='checkmark'></span> Remember me</label>
                                        <Link className='forgot-link ml-auto' to='/forgot-password'>Forgot your
                                            password?</Link>
                                    </div>
                                </Form.Group>

                                <Form.Group className='input-field full-width form-btnbox mb-0'>
                                    <Button className='btn secondary w-100' variant="primary" type="submit">Log
                                        in</Button>
                                    {/*<Link className='btn primary w-100' to='/dashboard'>Log in</Link>*/}
                                </Form.Group>

                            </Form>

                            {/*<div className='or-devider'><span>OR</span></div>*/}

                            {/*<div className='link-box'>
                            <p>Doesn't have an account yet? <Link to='/register'>Create account</Link></p>
                        </div>*/}
                            <div className='link-box'>
                                <p>Want To Login With Demo Account? <Link to='/demo-create'>Click Here</Link></p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;
