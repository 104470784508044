import React, {useState, useEffect} from 'react';
import './App.css';
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import Layout from "./layout/First";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from './pages/Register';
import ForgotPassword from './pages/ForgotPassword';

import SymbolDetailsHtml from "./pages/SymbolDetailsHtml";
import SearchPage from "./pages/SearchPage";
import PrivateRoute from "./services/PrivateRoute";
import DepositRequest from "./pages/DepositRequest";
import Withdraw from "./pages/Withdraw";
import Notification from "./pages/Notification";
import TermsAndConditions from "./pages/TermsAndConditions";

import Position from "./pages/Position";
import Trades from "./pages/Trades";
import SLTP from "./pages/SLTP";
import Profile from "./pages/Profile";
import Account from "./pages/Account";
import Rejection from "./pages/Rejection";
import DownloadBill from "./pages/DownloadBill";
import ChangePass from "./pages/ChangePass";
import ReArrangeScripts from "./pages/ReArrangeScripts";
import ReArrangeScriptsOld from "./pages/ReArrangeScriptsOld";
import NotFound from "./pages/NotFound";
import LedgerAccountReport from "./pages/LedgerAccountReport";
import ViewRequest from "./pages/ViewRequest";
import ScriptQty from "./pages/ScriptQty";
import MarketTiming from "./pages/MarketTiming";
import DemoCreate from "./pages/DemoCreate";

function App() {
    const authenticated = JSON.parse(localStorage.getItem('authenticated'));
    useEffect(() => {
        if (authenticated?.userName) {
            document.title = authenticated.userName;
        } else {
            const parts = window.location.hostname.split('.');
            let domain = parts.length > 2 ? parts[1] : parts[0];
            document.title = (domain) ? domain : '';
        }
    }, []);
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout/>}>
                    <Route index element={<Login/>}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/demo-create" element={<DemoCreate/>}/>
                    <Route path='register' exact={true} element={<Register/>}/>
                    <Route path='/r/:userName/register/:userId' exact={true} element={<Register/>}/>
                    <Route path='forgot-password' exact={true} element={<ForgotPassword/>}/>
                </Route>

                <Route path="/" element={<PrivateRoute/>}>
                    <Route index element={<Home/>}/>
                    <Route path="/dashboard" element={<Home/>}/>
                    <Route path="/symbol-details" element={<SymbolDetailsHtml/>}/>
                    <Route path="/search" element={<SearchPage/>}/>
                    <Route path="/deposit-request" element={<DepositRequest/>}/>
                    <Route path="/withdraw" element={<Withdraw/>}/>
                    <Route path="/notification" element={<Notification/>}/>
                    <Route path="/rules-and-regulations" element={<TermsAndConditions/>}/>
                    <Route path="/script-quantity" element={<ScriptQty/>}/>
                    <Route path="/market-timing" element={<MarketTiming/>}/>
                    <Route path="/position" element={<Position/>}/>
                    <Route path="/sltp" element={<SLTP/>}/>
                    <Route path="/trades" element={<Trades/>}/>
                    <Route path="/profile" element={<Profile/>}/>
                    <Route path="/account" element={<Account/>}/>
                    <Route path="/rejection" element={<Rejection/>}/>
                    <Route path="/download-bill" element={<DownloadBill/>}/>
                    <Route path="/changepass" element={<ChangePass/>}/>
                    <Route path="/rearrange-scripts" element={<ReArrangeScripts/>}/>
                    <Route path="/rearrange-scripts-old" element={<ReArrangeScriptsOld/>}/>
                    <Route path="/ledger-account-report" element={<LedgerAccountReport/>}/>
                    <Route path="/view-request" element={<ViewRequest/>}/>
                </Route>

                <Route path="*" element={<NotFound/>}/>

            </Routes>
        </BrowserRouter>

    );
}

export default App;