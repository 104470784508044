import React, {useState, useEffect} from 'react'
import {Container, Row, Col, Form, Button} from 'react-bootstrap'
import {Link, useParams} from 'react-router-dom';
import axios from "axios";
import {useLocation, useNavigate} from 'react-router-dom';
import {toast, Bounce} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {v4 as uuidv4} from 'uuid';
import {
    API_ENDPOINT, SUCCESS, CHECKU, CREATE_NEW, CLIENT, SEND_OTP, VERIFY_OTP
} from "../constant/";
import {ToastContainer} from 'react-toastify';
import {encryptData, decryptData, decryptUrlData} from "../utils/crypto";
import {toastSuccess, toastError} from "../utils/toastMsg";

function DemoCreate() {
    const fullUrl = window.location.origin;
    let domain = window.location.hostname;
    const {userName, userId} = useParams();
    const navigate = useNavigate();
    const deviceType = localStorage.getItem('deviceType');
    const jwt_token = localStorage.getItem("token");
    const initialFormData = {
        name: "",
        phone: "",
        userName: "",
        role: CLIENT,
        password: "",
        retype_password: "",
        allowedDevices: 1,
    };
    const [formData, setFormData] = useState(initialFormData);
    const [rData, setRData] = useState({});
    const [timeLeft, setTimeLeft] = useState(30);
    const [isExpired, setIsExpired] = useState(false);
    const [isShowOtp, setIsShowOtp] = useState(false);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const showOtpClick = () => {
        setIsShowOtp(true)
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        // Perform validation here, for example:
        if (
            !formData.phone || !formData.name
        ) {
            toastError("Please fill in all fields")
            return;
        }
        try {
            const {deviceType, browser, userAgent, newDeviceId, ip} = await getDeviceInfo();
            let formdat = {
                "phone": formData.phone,
                "name": formData.name,
                "domain": domain,
                "loginBy": "Web",
                "browser": browser,
                "userAgent": userAgent,
                "deviceId": newDeviceId,
                "deviceType": deviceType,
                "ipAddress": ip,
            }
            let formDataParam = encryptData(formdat);
            formDataParam = JSON.stringify({data: formDataParam});
            axios
                .post(API_ENDPOINT + SEND_OTP, formDataParam, {
                    headers: {
                        'Content-Type': 'application/json',
                        "deviceType": deviceType
                    },
                })
                .then((response) => {
                    if (response.data.statusCode == SUCCESS) {
                        const rdata = decryptData(response.data.data)
                        toastSuccess(response.data.message);
                        setRData(rdata)
                        setTimeLeft(30);
                        setIsExpired(false);
                    } else {
                        toastError(response.data.message)
                    }
                })
                .catch((error) => {
                    toastError(error.response.data.message)
                    console.error("Login error:", error); // Handle error
                });
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };
    const handleVerifySubmit = async (e) => {
        e.preventDefault();
        // Perform validation here, for example:
        if (
            !formData.phone ||
            !formData.otp
        ) {
            toastError("Please fill in all fields")
            return;
        }
        try {
            const {deviceType, browser, userAgent, newDeviceId, ip} = await getDeviceInfo();
            let formdat = {
                "phone": formData.phone,
                "otp": formData.otp,
                "phoneInquiryId": rData.phoneInquiryId
            }
            let formDataParam = encryptData(formdat);
            formDataParam = JSON.stringify({data: formDataParam});
            axios
                .post(API_ENDPOINT + VERIFY_OTP, formDataParam, {
                    headers: {
                        'Content-Type': 'application/json',
                        "deviceType": deviceType
                    },
                })
                .then((response) => {
                    if (response.data.statusCode == SUCCESS) {
                        const rdata = decryptData(response.data.data)
                        toastSuccess(response.data.message);
                        localStorage.setItem(
                            "authenticated",
                            JSON.stringify(rdata)
                        );
                        localStorage.setItem("token", response.data.meta.token);
                        localStorage.setItem("login", true);
                        localStorage.setItem("isNotificationRead", 0);
                        localStorage.setItem("isTermsAndConditions", 0);
                        window.location.href = `${fullUrl}/dashboard`;
                    } else {
                        toastError(response.data.meta.message)
                    }
                })
                .catch((error) => {
                    toastError(error.response.data.message)
                    console.error("Login error:", error); // Handle error
                });
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };
    const handleReset = () => {
        setFormData(initialFormData);
    };
    const getDeviceInfo = async () => {
        const userAgent = navigator.userAgent;
        let newDeviceType = "desktop";
        let browser = "Unknown";

        // Check if the device is mobile
        if (/Mobi|Android/i.test(userAgent)) {
            newDeviceType = "mobile";
        }

        // Browser detection (simplified)
        if (userAgent.includes("Chrome")) {
            browser = "Chrome";
        } else if (userAgent.includes("Firefox")) {
            browser = "Firefox";
        } else if (userAgent.includes("Safari")) {
            browser = "Safari";
        } else if (userAgent.includes("Edge")) {
            browser = "Edge";
        }
        let newDeviceId = localStorage.getItem('deviceId');
        if (!newDeviceId) {
            newDeviceId = uuidv4();
            localStorage.setItem('deviceId', newDeviceId);
        }
        let deviceType = localStorage.getItem('deviceType');
        if (!deviceType) {
            deviceType = newDeviceType;
            localStorage.setItem('deviceType', deviceType);
        }
        let ip = '';
        try {
            const response = await axios.get('https://api.ipify.org?format=json');
            ip = response.data.ip;
            return {deviceType, browser, userAgent, newDeviceId, ip};
        } catch (error) {
            return {deviceType, browser, userAgent, newDeviceId, ip};
        }
    };
    useEffect(() => {
    }, []);

    useEffect(() => {
        if (timeLeft === 0) {
            setIsExpired(true);
            return;
        }
        const timer = setTimeout(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        return () => clearTimeout(timer);
    }, [timeLeft]);
    return (
        <div className="login-wrapper">
            <ToastContainer/>
            <div className='box-screen'>
                <div className="login-block d-flex v-center j-center">
                    <div className='form-wrapper ml-auto mr-auto'>

                        <h2 className='tl'>Create demo Account</h2>

                        <Form className='form-inner d-flex'>
                            <Form.Group className='input-field full-width'>
                                <label className='label'>Name<em className='required'>*</em></label>
                                <Form.Control type='text' id="name" name="name" value={formData.name}
                                              placeholder="Enter Name" onChange={handleChange}/>
                            </Form.Group>
                            <Form.Group className='input-field full-width'>
                                <label className='label'>Mobile Number<em className='required'>*</em></label>
                                <Form.Control type='text' id="phone" name="phone" value={formData.phone}
                                              placeholder="Enter Mobile Number" onChange={handleChange}/>
                            </Form.Group>
                            {rData?.otp ?
                                <Form.Group className='input-field full-width'>
                                    <label className='label'>Enter OTP<em className='required'>*</em></label>
                                    <Form.Control type='text' id="otp" name="otp" value={formData.otp}
                                                  placeholder="Enter OTP" onChange={handleChange}/>
                                </Form.Group>
                                : ""}

                            {rData?.otp && timeLeft != 0 ?
                                <div className='link-box'>
                                    <p>Resend Otp : <strong>{timeLeft}s</strong></p>
                                </div>
                                : ""}
                            {rData?.otp && timeLeft == 0 && isShowOtp ?
                                <div className='link-box'>
                                    <p>Enter <strong>{rData.otp}</strong> as OTP Value to Complete Demo Account Creation
                                    </p>
                                </div>
                                : ""}
                            {rData?.otp && timeLeft == 0 && !isShowOtp ?
                                <div className='link-box'>
                                    <span className="text-primary" onClick={showOtpClick}>Show OTP</span>
                                </div>
                                : ""}
                            {rData?.otp ?
                                <Form.Group className='input-field full-width form-btnbox mb-0'>
                                    <Button className='btn primary w-100' variant="primary" type="submit"
                                            onClick={handleVerifySubmit}>Verify OTP</Button>
                                </Form.Group>
                                :
                                <Form.Group className='input-field full-width form-btnbox mb-0'>
                                    <Button className='btn primary w-100' variant="primary" type="submit"
                                            onClick={handleSubmit}>Send OTP</Button>
                                </Form.Group>
                            }
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DemoCreate