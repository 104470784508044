
export const API_ENDPOINT = process.env.REACT_APP_ADMIN_API_ENDPOINT;
//'https://a.domain.com:4001/api/v1/';




export const SUCCESS = 200;
export const SERVERERROR = 500;
export const FAILURE = 400;
export const UNAUTHORISED = 401;
export const DELETED = 3;
export const ACTIVE = 1;
export const INACTIVE = 2;
export const CREDIT = "credit";
export const DEBIT = "debit";
export const SUPER_ADMIN = '64b63755c71461c502ea4714';
export const ADMIN = '64b63755c71461c502ea4714';
export const MASTER = '64b63755c71461c502ea4715';
export const CLIENT = '64b63755c71461c502ea4717';
export const MCX = 'MCX';
export const CE_PE = 'CE/PE';
export const BUY = 'buy';
export const SELL = 'sell';
export const MARKET = 'market';
export const LIMIT = 'limit';
export const PENDING = 'pending';
export const STOPLOSS = 'stopLoss';
export const INTRADAY = 'intraday';
export const LONGTERM = 'longTerm';
export const EXECUTED = 'executed';
export const REJECTED = 'rejected';
export const CANCELLED = 'cancelled';
export const BANNER_VIEW_FOR_USER_IN = 'banner/view-for-user-in';
export const AUTH_LOGIN = 'user/user-login';
export const CREATE_NEW = 'user/create-new';
export const SEND_OTP = 'user/send-otp';
export const VERIFY_OTP = 'user/verify-otp';
export const AUTH_USER = 'user/user-view-profile';
export const CHECKU = 'user/check-u';
export const GET_PARENT_MOBILE = 'user/get-parent-mobile';
export const CHANGE_PASSWORD = 'user/change-password';
export const LOGOUT = 'user/logout';
export const GET_USER_TAB_LIST = 'user/get-user-tab-list';
export const GET_USER_TAB_LIST_WITH_SYMBOL = 'user/get-user-tab-list-with-symbol';
export const GET_USER_TAB_WISE_SYMBOLS_LIST = 'user/get-user-tab-wise-symbols-list';
export const DELETE_USER_TAB_SYMBOL = 'user/delete-user-tab-symbol';
export const POST_USER_TAB_WISE_SYMBOL = 'user/post-user-tab-wise-symbols';
export const POST_USER_TAB_SYMBOL_CHANGE_SEQUENCE = 'user/post-tab-symbol-change-sequence';
export const ANNOUNCEMENT_LIST = 'announcement/list';
export const SYMBOL_SEARCH_LIST = 'symbol/search-list';
export const BANK_DETAILS_VIEW_FOR_USER = 'bank-details/view-for-user';
export const PAYMENT_REQUEST_MK = 'payment-request-mk';
export const USER_WISE_EXCHANGE_LIST = 'user-wise-exchange/list';
export const SYMBOL_HISTORICAL_LIST = 'symbol-historical/list';
export const ADD_DEPOSIT = 'add-deposit';
export const ADD_WITHDRAWAL = 'add-withdrawal';
export const TRADE_CREATE = 'trade/create';
export const TRADE_UPDATE = 'trade/update';
export const TRADE_SQ_OFF_POSITION = 'trade/square-off-position';
export const TRADE_CARRYFORWARD_POSITION = 'trade/carry-forward-position';
export const TRADE_CANCEL = 'trade/cancel';
export const TRADE_LIST = 'trade/list';
export const REJECT_TRADE_LIST = 'reject-trade/list';
export const POSITION_LIST = 'position/list';
export const BILL_GENERATE = 'bill-generate';
export const SETTINGS = 'settings';
export const USER_WISE_SYMBOL_LIST = 'user-wise-symbol/list';
export const EXCHANGE_TIME_SCHEDULE_LIST = 'exchange-time-schedule/list';
export const EXCHANGE_LIST = 'exchange/list';
export const HISTORICAL_INTERVAL_ARRAY = [
    { name: "Minute", id: "minute", value: 1 },
    { name: "3 Minute", id: "3minute", value: 3 },
    { name: "5 Minute", id: "5minute", value: 5 },
    { name: "10 Minute", id: "10minute", value: 10 },
    { name: "15 Minute", id: "15minute", value: 15 },
    { name: "30 Minute", id: "30minute", value: 30 },
    { name: "60 Minute", id: "60minute", value: 60 },
    { name: "Day", id: "day", value: 1440 }, // 1 day = 1440 minutes
];